* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'poppins', sans-serif;
}

.container {
    width: 100%;
    height: 100vh;
    /*background-image: linear-gradient(to right, #1f2329, #191f23);*/
    background: #1f2329;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.chatbox {
    height: 100vh;
    background: #1f2329;
    width: 70%;
    min-width: 60%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.col-1 {
    flex: 1;
    overflow: auto;
}


.msg-row {
    display: flex;
    align-items: center;
    margin: 5px 20px 5px 20px;

}

.msg-sender {
    justify-content: flex-end;
}

.msg-text-sender {
    /*background: rgba(255,255,255,0.1);*/
    padding: 10px 20px;
    border-radius: 8px;
    flex-basis: 40%;
    font-weight: 100;
    background-color: #464646;
    color: white;
}

.msg-text {
    /*background: rgba(255,255,255,0.1);*/
    padding: 10px 20px;
    border-radius: 8px;
    flex-basis: 40%;
    font-weight: 100;
    background-color: #6d37da;
}

.msg-row2 {
    justify-content: flex-end;
}

.msg-row2 .msg-text {
    background-color: #8d8393;
}

.chatbox form {
    border: 0;
    outline: none;
    /*position: fixed;*/
    bottom: 0;
    width: 100%;
    background: #191f23;
    display: inline-flex;
}
.chatbox input {
    border: 0;
    outline: none;
    padding: 10px 20px;
    background: #4f4e4e;
    color: #fff;
    font-size: 16px;
    font-weight: 200;

}

.chatbox textarea {
    resize: none;
    background:#191f23;
    border: none;
    outline: none;
    margin: 10px 20px;
    color: #fff;
    flex: 100%;
}

.chatbox form .sendButton {
    background-color: #313131;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0 16px;
    color: #fff;
}

.chatbox form .sendButton:hover {
    background-color: #464545;
}

.padd {
   padding-top: 20px;
}

.header {
    background: #191f23;
    padding: 10px 10px;
    width: 100vw;
}

.reply-btn {
    color: rgba(255, 255, 255, 0.68);
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
    cursor: pointer;
}

.replyText {
    font-size: 10px;
    color: gray;
}

.replyWrapper {
    width: 100%;
}

.replyBanner {
    width: 100%;
    padding: 10px 20px;
}

::placeholder {
    color: #fff;
}
